<template>
  <footer class="footer min-w-1400 max-w-1400">
    <div class="footer-content">
      <p class="firstLine">
        Regulado por la Comisión Nacional Bancaria y la Comisión de Valores
      </p>
      <p class="secondLine">Copyright © | MARISCOS KANSAN, S. DE R.L. DE C.V.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: white;
  height: 100px;
  background-color: #4EAF4E;

}

.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.firstLine {
  width: 1270px;
  height: 20px;
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  font-weight: 400;
  color: rgba(255, 255, 255,0.9); 
  line-height: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.secondLine {
  width: 1098px;
  height: 20px;
  font-size: 16px;
  font-family: SF Pro-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 22px;
}
</style>
